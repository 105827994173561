import {
    UseMutationOptions,
    UseQueryOptions,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { env } from "@/app/_types/env";
import {
    ErrorResponse,
    FilterKeys,
    HttpMethod,
    OperationRequestBodyContent,
    PathsWithMethod,
    ResponseContent,
    ResponseObjectMap,
} from "openapi-typescript-helpers";
import type { paths as pathsInternal } from "../_types/models-internal.gen";
import type { paths as pathsPublic } from "../_types/models.gen";

type paths = pathsPublic & pathsInternal;

function replaceUrlParams(url: string, params: Record<string, string | number | boolean | undefined>): string {
    return url.replace(/\{([a-zA-Z_]+)\}/g, (_, key) => {
        if (params[key] !== undefined) {
            return encodeURIComponent(String(params[key]!));
        }
        throw new Error(`Missing parameter ${key} in URL replacement.`);
    });
}

/** Get the first success status */
// prettier-ignore
export type FirstSuccessStatus<T> =
  T extends { 200: unknown }   ? T[200] :
  T extends { 201: unknown }   ? T[201] :
  T extends { 202: unknown }   ? T[202] :
  T extends { 203: unknown }   ? T[203] :
  T extends { 204: unknown }   ? T[204] :
  T extends { 205: unknown }   ? T[205] :
  T extends { 206: unknown }   ? T[206] :
  T extends { 207: unknown }   ? T[207] :
  T extends { "2XX": unknown } ? T["2XX"] : never;

type TSuccess<Method extends HttpMethod, Path extends PathsWithMethod<paths, Method>> = FilterKeys<
    ResponseContent<FirstSuccessStatus<ResponseObjectMap<paths[Path][Method]>>>,
    "application/json"
>;

export type TError<Method extends HttpMethod, Path extends PathsWithMethod<paths, Method>> = {
    status: number;
    message: string;
    data: ErrorResponse<ResponseObjectMap<paths[Path][Method]>, "application/json">;
};

export type UseApiQueryResult<Path extends PathsWithMethod<paths, "get">> = UseQueryResult<
    TSuccess<"get", Path>,
    TError<"get", Path>
>;

type TOptions<Method extends HttpMethod, Path extends PathsWithMethod<paths, Method>> = UseQueryOptions<
    TSuccess<Method, Path>,
    TError<Method, Path>
>;

type PathParams<Method extends HttpMethod, Path extends PathsWithMethod<paths, Method>> = paths[Path][Method] extends {
    parameters: unknown;
}
    ? paths[Path][Method]["parameters"]["path"]
    : never;

type QueryParams<Method extends HttpMethod, Path extends PathsWithMethod<paths, Method>> = paths[Path][Method] extends {
    parameters: unknown;
}
    ? paths[Path][Method]["parameters"]["query"]
    : never;

type Merge<S, T> = [S] extends [never]
    ? T
    : S extends undefined
      ? T
      : [T] extends [never]
        ? S
        : T extends undefined
          ? S
          : S & T;

type TVariables<Method extends HttpMethod, Path extends PathsWithMethod<paths, Method>> = Merge<
    NonNullable<OperationRequestBodyContent<paths[Path][Method]>>,
    Merge<QueryParams<Method, Path>, PathParams<Method, Path>>
>;

type TMutationOptions<Method extends HttpMethod, Path extends PathsWithMethod<paths, Method>> = UseMutationOptions<
    TSuccess<Method, Path>,
    TError<Method, Path>,
    TVariables<Method, Path>
>;

export const apiGet = async <Path extends PathsWithMethod<paths, "get">>(
    route: Path,
    params: paths[Path]["get"]["parameters"]["query"] = {},
    options: RequestInit = {},
): Promise<TSuccess<"get", Path>> => {
    const url = new URL(env.NEXT_PUBLIC_API_URL + route);
    if (params) {
        Object.entries(params).forEach(([key, value]) => {
            if (value !== undefined) {
                url.searchParams.append(key, String(value));
            }
        });
    }
    const response = await fetch(url.toString(), {
        method: "GET",
        credentials: "include",
        ...options,
    });
    if (!response.ok) {
        const errorBody = await response.json().catch(() => ({}));
        throw {
            status: response.status,
            message: response.statusText,
            data: errorBody,
        };
    }
    return response.json() as Promise<TSuccess<"get", Path>>;
};

export function useApiQuery<Path extends PathsWithMethod<paths, "get">>(
    route: Path,
    args: {
        queryParams?: QueryParams<"get", Path>;
        pathParams?: PathParams<"get", Path>;
        options?: Omit<Partial<TOptions<"get", Path>>, "queryFn">;
    } = {},
) {
    const apiURL = env.NEXT_PUBLIC_API_URL;
    const { queryParams, pathParams, options } = args;

    const defaultOptions: TOptions<"get", Path> = {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,

        queryKey: [
            route,
            queryParams || pathParams
                ? {
                      ...(queryParams ? { queryParams } : {}),
                      ...(pathParams ? { pathParams } : {}),
                  }
                : undefined,
        ].filter(Boolean),
    };
    const url = new URL(apiURL + replaceUrlParams(route, pathParams || {}));
    if (queryParams) {
        Object.entries(queryParams).forEach(([key, value]) => {
            if (value !== undefined) {
                url.searchParams.append(key, String(value));
            }
        });
    }

    const queryFn = async ({ signal }: { signal?: AbortSignal }): Promise<TSuccess<"get", Path>> => {
        const response = await fetch(url.toString(), {
            method: "GET",
            credentials: "include",
            signal,
        });
        if (!response.ok) {
            const errorBody = await response.json().catch(() => ({}));
            throw {
                status: response.status,
                message: response.statusText,
                data: errorBody,
            };
        }
        return response.json() as Promise<TSuccess<"get", Path>>;
    };

    return useQuery<TSuccess<"get", Path>, TError<"get", Path>>({
        ...defaultOptions,
        ...options,
        queryFn,
    });
}

function useApiMutation<T, R, E = unknown>(
    route: string,
    options?: UseMutationOptions<T, E, R>,
    invalidate?: (resp: T, variables: R) => QueryKey[],
    method: HttpMethod = "post",
) {
    const apiURL = env.NEXT_PUBLIC_API_URL;
    const queryClient = useQueryClient();

    const mutationOptions: UseMutationOptions<T, E, R> = {
        ...options,
        mutationFn: async (data: R): Promise<T> => {
            let url = `${apiURL}${route}`;
            if (method === "delete" || method === "put") {
                url = replaceUrlParams(url, data as unknown as Record<string, string | number | boolean | undefined>);
            }

            const response = await fetch(url, {
                method,
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: method !== "get" && method !== "head" ? JSON.stringify(data) : undefined,
            });

            if (!response.ok) {
                const errorBody = await response.json().catch(() => ({}));
                throw {
                    status: response.status,
                    message: response.statusText,
                    data: errorBody,
                };
            }

            if (response.headers.get("Content-Type")?.includes("application/json")) {
                return response.json() as Promise<T>;
            } else {
                return response.text() as unknown as Promise<T>;
            }
        },
        onSuccess: async (resp: T, variables: R, context: unknown) => {
            if (invalidate) {
                const keys = invalidate(resp, variables);

                for (let i = 0; i < keys.length; i++) {
                    await queryClient.invalidateQueries({ queryKey: keys[i] });
                }
            }
            return options?.onSuccess?.(resp, variables, context);
        },
    };

    return useMutation<T, E, R>(mutationOptions);
}

type QueryKeyInner<K extends PathsWithMethod<paths, "get">> =
    | [K]
    | [K, Partial<paths[K]["get"]["parameters"]["query"]>];

type QueryKey = QueryKeyInner<PathsWithMethod<paths, "get">>;

export const useApiPost = <Path extends PathsWithMethod<paths, "post">>(
    route: Path,
    options?: TMutationOptions<"post", Path>,
    invalidate?: (resp: TSuccess<"post", Path>, variables: TVariables<"post", Path>) => QueryKey[],
) => {
    return useApiMutation<TSuccess<"post", Path>, TVariables<"post", Path>, TError<"post", Path>>(
        route,
        options,
        invalidate,
        "post",
    );
};

export const useApiPut = <Path extends PathsWithMethod<paths, "put">>(
    route: Path,
    options?: TMutationOptions<"put", Path>,
    invalidate?: (resp: TSuccess<"put", Path>, variables: TVariables<"put", Path>) => QueryKey[],
) => {
    return useApiMutation<TSuccess<"put", Path>, TVariables<"put", Path>, TError<"put", Path>>(
        route,
        options,
        invalidate,
        "put",
    );
};

export const useApiDelete = <Path extends PathsWithMethod<paths, "delete">>(
    route: Path,
    options?: TMutationOptions<"delete", Path>,
    invalidate?: (resp: TSuccess<"delete", Path>, variables: TVariables<"delete", Path>) => QueryKey[],
) => {
    return useApiMutation<TSuccess<"delete", Path>, TVariables<"delete", Path>, TError<"delete", Path>>(
        route,
        options,
        invalidate,
        "delete",
    );
};
