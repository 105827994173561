import { z } from "zod";

const ETH_ADDRESS_PATTERN = /^0x[a-fA-F0-9]{40}$/;
const WS_URL_PATTERN = /^wss?:\/\/.+/;

export const envSchema = z.object({
    NEXT_PUBLIC_API_URL: z.string().url().describe("Exchange API URL"),
    NEXT_PUBLIC_AUTH_URL: z.string().url().describe("Auth webapp URL"),
    NEXT_PUBLIC_AUTH_API_URL: z.string().url().describe("Auth API URL"),
    NEXT_PUBLIC_WEBSOCKET_URL: z
        .string()
        .regex(WS_URL_PATTERN, "Must be a valid WebSocket URL starting with ws:// or wss://"),

    NEXT_PUBLIC_SEASON_1_AIRDROP_ADDRESS: z
        .string()
        .regex(ETH_ADDRESS_PATTERN, "Must be a valid Ethereum address (0x followed by 40 hex characters)")
        .transform((val): `0x${string}` => val as `0x${string}`),

    // should this be public?
    NEXT_PUBLIC_MOONPAY_PUBLIC_KEY: z.string().optional().describe("MoonPay public API key (optional)"),

    NEXT_PUBLIC_ENV: z.enum(["local", "stage", "prod"]).describe("Fake env var to include stage"),
    NEXT_PUBLIC_VERSION: z.string().optional().describe("Build version from git SHA (optional in local development)"),
});

export type Env = z.infer<typeof envSchema>;

export function loadEnv(): Env {
    try {
        const envVars = {
            NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL,
            NEXT_PUBLIC_AUTH_URL: process.env.NEXT_PUBLIC_AUTH_URL,
            NEXT_PUBLIC_AUTH_API_URL: process.env.NEXT_PUBLIC_AUTH_API_URL,
            NEXT_PUBLIC_WEBSOCKET_URL: process.env.NEXT_PUBLIC_WEBSOCKET_URL,
            NEXT_PUBLIC_SEASON_1_AIRDROP_ADDRESS: process.env.NEXT_PUBLIC_SEASON_1_AIRDROP_ADDRESS,
            NEXT_PUBLIC_MOONPAY_PUBLIC_KEY: process.env.NEXT_PUBLIC_MOONPAY_PUBLIC_KEY,
            NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
            NEXT_PUBLIC_VERSION: process.env.NEXT_PUBLIC_VERSION,
        };

        return envSchema.parse(envVars);
    } catch (error) {
        if (error instanceof z.ZodError) {
            const missingVars = new Set<string>();
            const invalidVars = new Map<string, string>();

            error.errors.forEach((e) => {
                const path = e.path.join(".");

                if (e.code === "invalid_type" && e.received === "undefined") {
                    missingVars.add(path);
                } else {
                    invalidVars.set(path, e.message);
                }
            });

            console.error("\n❌ Environment configuration error:");

            if (missingVars.size > 0) {
                console.error("\nMissing required environment variables:");
                missingVars.forEach((v) => console.error(`  - ${v}`));
            }

            if (invalidVars.size > 0) {
                console.error("\nInvalid environment variables:");
                invalidVars.forEach((msg, v) => console.error(`  - ${v}: ${msg}`));
            }

            console.error("\nPlease check your .env file or environment configuration.\n");
        } else {
            console.error("\n❌ Unexpected error validating environment variables:", error);
        }

        throw new Error("Environment validation failed");
    }
}

export const env = loadEnv();
