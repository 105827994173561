import { AssetsResponse, IndexPrice, Pair, Ticker } from "@/app/_hooks/types";
import { env } from "@/app/_types/env";
import { UTCTimestamp } from "lightweight-charts";
import { UserSettings } from "./types";
import { MergedTicker } from "./useFetch";

export async function updateEmail(newEmail: string, headers?: Record<string, string>) {
    const response = await fetch(`${env.NEXT_PUBLIC_API_URL}/auth/email/change`, {
        method: "POST",
        body: JSON.stringify({ newEmail }),
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            ...headers,
        },
    });
    if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || `Request failed with status ${response.status}`);
    }
    return response;
}

export async function updatePassword(oldPassword: string, newPassword: string, headers?: Record<string, string>) {
    const response = await fetch(`${env.NEXT_PUBLIC_API_URL}/auth/password`, {
        method: "PUT",
        body: JSON.stringify({ oldPassword, newPassword }),
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            ...headers,
        },
    });

    if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || `Request failed with status ${response.status}`);
    }
    return response;
}

export async function deleteUser(headers?: Record<string, string>) {
    const response = await fetch(`${env.NEXT_PUBLIC_API_URL}/auth/user`, {
        method: "DELETE",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            ...headers,
        },
    });
    if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || `Request failed with status ${response.status}`);
    }
    return response;
}

export async function fetchTickers(): Promise<Ticker[]> {
    const response = await fetch(`${env.NEXT_PUBLIC_API_URL}/public/tickers`, {
        next: { revalidate: 60 },
    });
    if (!response.ok) {
        throw new Error("Failed to fetch tickers data");
    }

    return response.json();
}

export async function fetchPair(symbol: string): Promise<Pair> {
    const url = new URL(`${env.NEXT_PUBLIC_API_URL}/public/pair`);

    url.searchParams.append("symbol", String(symbol));

    const response = await fetch(url.toString(), {
        next: { revalidate: 60 },
    });

    if (!response.ok) {
        throw new Error("Failed to fetch pair data");
    }
    const res = await response.json();
    return res;
}

export async function fetchTicker(symbol: string): Promise<Ticker> {
    const response = await fetch(`${env.NEXT_PUBLIC_API_URL}/public/ticker?symbol=${symbol}`, {
        next: { revalidate: 10 },
    });
    if (!response.ok) {
        throw new Error("Failed to fetch ticker data");
    }
    return response.json();
}

export async function fetchAssets(): Promise<AssetsResponse> {
    const response = await fetch(`${env.NEXT_PUBLIC_API_URL}/public/assets`, { next: { revalidate: 60 } });

    if (!response.ok) {
        throw new Error("Failed to fetch assets data");
    }

    return response.json();
}

export async function fetchPairs(): Promise<Pair[]> {
    const response = await fetch(`${env.NEXT_PUBLIC_API_URL}/public/pairs`, { next: { revalidate: 60 } });

    if (!response.ok) {
        throw new Error("Failed to fetch pairs data");
    }

    return response.json();
}

export function mergeTickers(tickers: Ticker[], pairs: Pair[]): MergedTicker[] {
    const pairLookup = new Map(pairs.map((item) => [item.symbol, item]));

    return tickers.reduce((acc, item) => {
        const pairItem = pairLookup.get(item.symbol);
        if (pairItem) {
            acc.push({ ...item, ...pairItem });
        }
        return acc;
    }, [] as MergedTicker[]);
}

export type MarketCapDataPoint = {
    time: UTCTimestamp;
    value: number;
};

type MarketCapChartData = {
    market_cap_chart: {
        market_cap: [number, number][];
        volume: [number, number][];
    };
};

export async function fetchChartData() {
    const chartResponse = await fetch(`${env.NEXT_PUBLIC_API_URL}/public/marketcapchart`, {
        next: { revalidate: 60 },
    });

    if (!chartResponse.ok) {
        throw new Error("Failed to fetch data");
    }

    const chartData: MarketCapChartData = await chartResponse.json();

    const transformedData: MarketCapDataPoint[] = chartData.market_cap_chart.market_cap.map(([time, value]) => ({
        time: (time / 1000) as UTCTimestamp,
        value: value,
    }));
    return transformedData;
}

export async function fetchSettings(): Promise<UserSettings> {
    const response = await fetch(`${env.NEXT_PUBLIC_API_URL}/account/settings`, {
        next: { revalidate: 60 },
        credentials: "include",
    });
    return response.json();
}

export async function fetchIndexPrices(): Promise<IndexPrice[]> {
    const response = await fetch(`${env.NEXT_PUBLIC_API_URL}/public/index-prices`, {
        next: { revalidate: 60 },
    });

    if (!response.ok) {
        throw new Error("Failed to fetch index prices data");
    }

    return response.json();
}
